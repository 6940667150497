<template>
  <q-form ref="formRef_3_1">
    <div class="q-gutter-y-md" v-if="panels !== false">
      <PanelProductSelection v-if="settings?.product_selection == 'Panel'" />
      <ListProductSelection v-if="settings?.product_selection == 'List'" />
      <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
          <StartDate
            v-if="component_settings.StartDate"
            :startDateRules="startDateObj"
          />
        </div>
      </div>
    </div>
    <StepperNavigation
      class="row justify-end q-pb-md"
      @next-step="nextStep()"
      @previous-step="previousStep()"
    />
  </q-form>
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { defineAsyncComponent } from "vue";
import { getStartDateObj } from "../../plugins/start-date.js";

const PanelProductSelection = defineAsyncComponent(() =>
  import("@/components/MultiOffer/Offers/PanelProductSelection.vue")
);
const ListProductSelection = defineAsyncComponent(() =>
  import("@/components/MultiOffer/Offers/ListProductSelection.vue")
);
const StartDate = defineAsyncComponent(() =>
  import("@/components/Offers/Startdate.vue")
);
const StepperNavigation = defineAsyncComponent(() =>
  import("@/components/StepperNavigation.vue")
);
//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  component_settings: Object,
  settings: Object,
});

//* VueUse
const $store = useStore();
const $q = useQuasar();

//* Refs
const formRef_3_1 = ref(null);
const startDateObj = ref(getStartDateObj());

//* Computed
const panels = computed(() => $store.getters?.products?.options ?? false);
const selectedProducts = computed(() => $store.getters.selectedProducts ?? []);

//* Methods
function previousStep() {
  $store.dispatch("decrementCurrentStep");
  $store.dispatch("setProjectSpecificValue", {
    key: "redo_offer",
    value: true,
  });
}

function nextStep() {
  if (!selectedProducts.value.length) {
    $q.notify({
      type: "negative",
      message: "Geen product geselecteerd",
      position: "top",
    });
    return;
  }
  if (
    props.settings.offer &&
    (Object.entries($store.getters.poll).length != 0 ||
      $store.getters.pendingRequests > 0)
  ) {
    $q.notify({
      type: "negative",
      message: "AOM nog niet afgerond",
      position: "top",
    });
    return;
  }

  var allAomFinished = true;
  if (props.settings.offer) {
    selectedProducts.value.forEach((product) => {
      let translated;
      if (
        !(translated = JSON.parse(product.offer_translated)) ||
        !translated._Campaign_code
      ) {
        $q.notify({
          type: "negative",
          message:
            "Niet alle AOM's zijn afgerond. Selecteer een incentive of deselecteer de producten",
          progress: true,
          timeout: 5000,
          position: "top",
        });

        allAomFinished = false;
      }
    });
  }
  formRef_3_1.value.validate().then((success) => {
    if (success && allAomFinished) {
      $store.dispatch("incrementCurrentStep");
    }
  });
}
</script>

<style lang="sass">
.q-table tbody td
  height: 55px
.selected
  transition: 6s
</style>
